(function(){
	'use strict';
	angular
	.module('editorApp')
	.factory('UidService',UidService);

	UidService.$inject = ['$localStorage'];
	
	function UidService($localStorage){
		var service={
			get:get
		}
		function get(){
			var d = new Date().getTime();
			var uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
			});
			if($localStorage && $localStorage.user && $localStorage.user.id){
				uid += '-' + $localStorage.user.id;
			}
			return uid;
		}
		return service;
	}

})();